export const email_RegExp = new RegExp(
  /^[A-Za-z_0-9\.]+@[A-Za-z]+\.[A-Za-z]{2,4}$/
);
export const spotify_RegExp = new RegExp(
  /https:\/\/open\.spotify\.com\/(track|episode|playlist|show)\/[a-zA-Z0-9?=]+/
);
export const tiktok_RegExp = new RegExp(
  /^https:\/\/(www|m)\.tiktok\.com\/(@[A-Za-z0-9_\.]+\/video\/[0-9]+(\?[_A-Za-z0-9&=]+)?|v\/[0-9]+\.html\?_d=.+)$/
);
export const tiktokShort_RegExp = new RegExp(
  /^https:\/\/vm\.tiktok\.com\/[A-Za-z0-9\/]{5,}([?A-Za-z=0-9+&_]+)?$/
);
export const twitter_RegExp = new RegExp(
  /^https:\/\/twitter\.com\/[A-Za-z0-9_]+\/status\/[0-9]+(\?[A-Za-z0-9=&_-]+)?$/
);
export const mobile_RegExp = new RegExp(
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/
);
export const facebookShort_RegExp = new RegExp(
  /^https:\/\/fb\.gg\/v\/[A-Za-z0-9]{4,}(\/)?$/
);
export const superfans_RegExp = {
  discord: new RegExp(/^(https:\/\/)?discord\.gg\/[A-Za-z0-9]+$/),
  telegram: new RegExp(/^(https:\/\/)?t\.me\/[+A-Za-z0-9]+$/),
  whatsapp: new RegExp(
    /^(https:\/\/)?(wa\.me|chat\.whatsapp\.com)\/[0-9A-Za-z]+$/
  )
};
export const linktree_RegExp = new RegExp(
  /^(https:\/\/)?linktr\.ee\/[A-Za-z0-9]+(\?[A-Za-z0-9=&_-]+)?$/
);
export const linktree_RegExp_2 = new RegExp(
  /^(https:\/\/|www\.)?linktr\.ee\/[A-Za-z0-9]{3,}$/
);
export const solscan_RegExp = new RegExp(
  /^(https:\/\/)?solscan\.io\/token\/+[\s\S]*$/
);
