import { AxiosApi } from "../AxiosBase";
import { IUserEdit } from "types/User";
import { getCookie } from "helpers/handleCookies";


const AxiosUpdateUserSettings = async (payload: IUserEdit) => {
    const userToken = getCookie("token");

    if (!userToken) return { error: "No estas logeado" }

    try {
        const { data } = await AxiosApi.post(
            "/user/update",
            payload,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${userToken}`
                }
            }
        );

        if (data.message !== "OK") return { error: data.message }

        return { success: "PRO" }
    }

    catch(e) {
        console.log(e);
        console.log("AxiosUpdateUserSettings() Error");
        return { error: "Error al conectarse con el servidor" }
    }

};

export default AxiosUpdateUserSettings;