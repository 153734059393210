const ColorsDeclaration: React.FC = () => {
  return (
    <div>
      <div className="hidden bg-red-500 fill-red-500 text-red-500  hover:bg-red-800 disabled:hover:bg-red-500" />
      <div className="hidden bg-red-100 hover:bg-red-200 disabled:hover:bg-red-100" />

      <div className="hidden bg-pink-500 fill-pink-500  text-pink-500 hover:bg-pink-800" />
      <div className="hidden bg-pink-100 hover:bg-pink-200 disabled:hover:bg-pink-100" />

      <div className="hidden bg-amber-500 fill-amber-500  text-amber-500 hover:bg-amber-800" />
      <div className="hidden bg-amber-100 hover:bg-amber-200 disabled:hover:bg-amber-100" />

      <div className="hidden bg-yellow-500 fill-yellow-500  text-yellow-500 hover:bg-yellow-800" />
      <div className="hidden bg-yellow-100 hover:bg-yellow-200 disabled:hover:bg-yellow-100" />

      <div className="hidden bg-green-500 fill-green-500   text-green-500 hover:bg-green-800" />
      <div className="hidden bg-green-100 hover:bg-green-200 disabled:hover:bg-green-100" />

      <div className="hidden bg-cyan-500 fill-cyan-500  text-cyan-500 hover:bg-cyan-800" />
      <div className="hidden bg-cyan-100 hover:bg-cyan-200 disabled:hover:bg-cyan-100" />

      <div className="hidden bg-blue-500 fill-blue-500   text-blue-500 hover:bg-blue-800 disabled:hover:bg-blue-500" />
      <div className="hidden bg-blue-100 hover:bg-blue-200 disabled:hover:bg-blue-100" />

      <div className="hidden bg-purple-500 fill-purple-500  text-purple-500 hover:bg-purple-800" />
      <div className="hidden bg-purple-100 hover:bg-purple-200 disabled:hover:bg-purple-100" />

      <div className="hidden bg-fuchsia-500 fill-fuchsia-500   text-fuchsia-500 hover:bg-fuchsia-800" />
      <div className="hidden bg-fuchsia-100 hover:bg-fuchsia-200 disabled:hover:bg-fuchsia-100" />

      <div className="hidden bg-jacarta-500 fill-jacarta-500   text-jacarta-500 hover:bg-jacarta-800" />
      <div className="hidden bg-jacarta-100 hover:bg-jacarta-200 disabled:hover:bg-jacarta-100" />

      <div className="hidden bg-ultramarine-500 fill-ultramarine-500   text-ultramarine-500 hover:bg-ultramarine-800" />
      <div className="hidden bg-ultramarine-100 hover:bg-ultramarine-200 disabled:hover:bg-ultramarine-100" />

      <div className="hidden bg-darktan-500 fill-darktan-500   text-darktan-500 hover:bg-darktan-800" />
      <div className="hidden bg-darktan-100 hover:bg-darktan-200 disabled:hover:bg-darktan-100" />

      <div className="hidden bg-mallard-500 fill-mallard-500   text-mallard-500 hover:bg-mallard-800" />
      <div className="hidden bg-mallard-100 hover:bg-mallard-200 disabled:hover:bg-mallard-100" />

      <div className="hidden bg-ebonyclay-500 fill-ebonyclay-500   text-ebonyclay-500 hover:bg-ebonyclay-800" />
      <div className="hidden bg-ebonyclay-100 hover:bg-ebonyclay-200 disabled:hover:bg-ebonyclay-100" />

      <div className="hidden bg-eagle-500 fill-eagle-500   text-eagle-500 hover:bg-eagle-800" />
      <div className="hidden bg-eagle-100 hover:bg-eagle-200 disabled:hover:bg-eagle-100" />

      <div className="hidden bg-red-900 hover:bg-red-700" />
      <div className="hidden bg-pink-900 hover:bg-pink-700" />
      <div className="hidden bg-amber-900 hover:bg-amber-700" />
      <div className="hidden bg-yellow-900 hover:bg-yellow-700" />
      <div className="hidden bg-green-900 hover:bg-green-700" />
      <div className="hidden bg-cyan-900 hover:bg-cyan-700" />
      <div className="hidden bg-blue-900 hover:bg-blue-700" />
      <div className="hidden bg-purple-900 hover:bg-purple-700" />
      <div className="hidden bg-fuchsia-900 hover:bg-fuchsia-700" />
      <div className="hidden bg-jacarta-900 hover:bg-jacarta-700" />
      <div className="hidden bg-ultramarine-900 hover:bg-ultramarine-700" />
      <div className="hidden bg-darktan-900 hover:bg-darktan-700" />
      <div className="hidden bg-mallard-900 hover:bg-mallard-700" />
      <div className="hidden bg-ebonyclay-900 hover:bg-ebonyclay-700" />
      <div className="hidden bg-eagle-900 hover:bg-eagle-700" />

      <div className="hidden bg-jacarta-500 text-jacarta-700 outline-jacarta-700 hover:bg-jacarta-50" />
      <div className="hidden bg-ultramarine-500 text-ultramarine-700 outline-ultramarine-700 hover:bg-ultramarine-50" />
      <div className="hidden bg-darktan-500 text-darktan-700 outline-darktan-700 hover:bg-darktan-50" />
      <div className="hidden bg-mallard-500 text-mallard-700 outline-mallard-700 hover:bg-mallard-50" />
      <div className="hidden bg-ebonyclay-500 text-ebonyclay-700 outline-ebonyclay-700 hover:bg-ebonyclay-50" />
      <div className="hidden bg-eagle-500 text-eagle-700 outline-eagle-700 hover:bg-eagle-50" />

      <div className="text-redred-800 hidden bg-red-50 hover:text-red-700 active:bg-red-100 active:text-red-800" />
      <div className="text-pinkpink-800 hidden bg-pink-50 hover:text-pink-700 active:bg-pink-100 active:text-pink-800" />
      <div className="hidden bg-amber-50 text-amber-800 hover:text-amber-700 active:bg-fuchsia-100 active:text-amber-800" />
      <div className="hidden bg-yellow-50 text-yellow-800 hover:text-yellow-700 active:bg-yellow-100 active:text-yellow-800" />
      <div className="hidden bg-green-50 text-green-800 hover:text-green-700 active:bg-green-100 active:text-green-800" />
      <div className="hidden bg-cyan-50 text-cyan-800 hover:text-cyan-700 active:bg-cyan-100 active:text-cyan-800" />
      <div className="hidden bg-blue-50 text-blue-800 hover:text-blue-700 active:bg-blue-100 active:text-blue-800" />
      <div className="hidden bg-purple-50 text-purple-800 hover:text-purple-700 active:bg-purple-100 active:text-purple-800" />
      <div className="hidden bg-fuchsia-50 text-fuchsia-800 hover:text-fuchsia-700 active:bg-fuchsia-100 active:text-fuchsia-800" />
      <div className="hidden bg-jacarta-50 text-jacarta-800 hover:text-jacarta-700 active:bg-jacarta-100 active:text-jacarta-800" />
      <div className="hidden bg-ultramarine-50 text-ultramarine-800 hover:text-ultramarine-700 active:bg-ultramarine-100 active:text-ultramarine-800" />
      <div className="hidden bg-darktan-50 text-darktan-800 hover:text-darktan-700 active:bg-darktan-100 active:text-darktan-800" />
      <div className="hidden bg-mallard-50 text-mallard-800 hover:text-mallard-700 active:bg-mallard-100 active:text-mallard-800" />
      <div className="hidden bg-ebonyclay-50 text-ebonyclay-800 hover:text-ebonyclay-700 active:bg-ebonyclay-100 active:text-ebonyclay-800" />
      <div className="hidden bg-eagle-50 text-eagle-800 hover:text-eagle-700 active:bg-eagle-100 active:text-eagle-800" />

      <div className="hidden text-red-700" />
      <div className="hidden text-pink-700" />
      <div className="hidden text-amber-700" />
      <div className="hidden text-yellow-700" />
      <div className="hidden text-green-700" />
      <div className="hidden text-cyan-700" />
      <div className="hidden text-blue-700" />
      <div className="hidden text-purple-700" />
      <div className="hidden text-fuchsia-700" />
      <div className="hidden text-jacarta-700" />
      <div className="hidden text-ultramarine-700" />
      <div className="hidden text-darktan-700" />
      <div className="hidden text-mallard-700" />
      <div className="hidden text-ebonyclay-700" />
      <div className="hidden text-eagle-700" />
    </div>
  );
};

export default ColorsDeclaration;
