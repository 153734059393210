// Helpers
import { classNames } from 'helpers/classNames';

type LabelProps = {
  name?: string;
  title: string;
  className?: string;
  subtitle?: string;
};

const Label: React.FC<LabelProps> = ({ name, title, className, subtitle }) => {
  return (
    <div className='flex gap-1'>
      <label
        htmlFor={name}
        className={classNames(
          className,
          'block text-base font-medium text-gray-700'
        )}
      >
        {title}
      </label>
      <span className='text-gray-400'>{subtitle}</span>
    </div>
  );
};

export default Label;
