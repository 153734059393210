import React from 'react';

interface ISpinnerLoaderProps {
  className?: string;
  color?: string;
}

const SpinnerLoader = ({
  className = '',
  color = '#FFFFFF'
}: ISpinnerLoaderProps) => {
  const borderClassName: string = `3px solid ${color}`;

  return (
    <div
      className={`h-5 w-5 animate-spin rounded-full ${className}`}
      style={{
        borderTop: borderClassName,
        borderRight: borderClassName,
        borderBottom: borderClassName,
        borderLeft: '3px solid transparent'
      }}
    ></div>
  );
};

export default SpinnerLoader;
