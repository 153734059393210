import { classNames } from 'helpers/classNames';
import Link from 'next/link';
import React from 'react';

// Components
import ColorsDeclarations from 'components/dashboard/settings/customProfile/ColorsDeclaration';
import SpinnerLoader from '../SpinnerLoader';

interface IButtonProps {
  className?: string;
  type?: 'button' | 'submit' | 'link';
  text: string;
  icon?: any;
  loading?: boolean;
  disabled?: boolean;
  href?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  style?: React.CSSProperties;
  target?: '_blank' | 'normal';
  variant?:
    | 'primary'
    | 'secondary'
    | 'customColorPrimary'
    | 'customColorSecondary';
  spinnerColor?: string;
  customColor?: string;
}

const Button = ({
  className = '',
  type = 'button',
  text,
  icon: Icon,
  loading = false,
  disabled = false,
  href = '#',
  onClick,
  style,
  target = 'normal',
  variant,
  spinnerColor = '',
  customColor = ''
}: IButtonProps) => {
  if (type !== 'button' && onClick)
    throw new Error(`<Button type="${type}" /> can't use onClick prop`);
  else if (type !== 'link' && (href !== '#' || target !== 'normal'))
    throw new Error(`<Button type="${type}" /> can't use href and target prop`);

  const classes = {
    variant: {
      primary:
        'h-12 bg-primary rounded-md font-medium text-gray-50 hover:bg-primary-800 disabled:opacity-50 disabled:hover:bg-primary',
      secondary:
        'h-12 bg-primary-200 rounded-md font-medium text-primary hover:bg-primary-300 disabled:opacity-50 disabled:hover:bg-primary-200',
      customColorPrimary: `h-12 bg-${customColor}-700 rounded-md text-white hover:bg-${customColor}-800 disabled:opacity-50 disabled:hover:bg-${customColor}-500 active:bg-${customColor}-900`,
      customColorSecondary: `h-12 bg-${customColor}-100 rounded-md text-${customColor}-500 hover:bg-${customColor}-200 disabled:opacity-50 disabled:hover:bg-${customColor}-100`
    }
  };

  const theClassName: string = `py-2 px-4 rounded ${className}`;

  if (type === 'link') {
    if (target === '_blank') {
      return (
        <a
          href={href}
          className={`inline-block ${theClassName}`}
          style={style}
          target="_blank"
        >
          {text}
        </a>
      );
    }

    return (
      <Link href={href}>
        <a className={`inline-block ${theClassName}`} style={style}>
          {text}
        </a>
      </Link>
    );
  }

  return (
    <button
      type={type}
      className={classNames(
        `flex items-center justify-center min-h-[56px] ${theClassName} ${
          disabled || loading ? 'opacity-50' : 'opacity-100'
        } ${className}`,
        variant && classes.variant[variant]
      )}
      style={style}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {loading ? (
        <SpinnerLoader className="mx-auto" color={spinnerColor} />
      ) : (
        <>
          {Icon && <Icon className="mr-3 w-[1rem]" />}
          {text}
        </>
      )}
      <ColorsDeclarations />
    </button>
  );
};

export default Button;
